<template>
  <div class="refreshPage">
    <el-dialog
      title="温馨提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <span>
        检测到用户在其他页面修改了缓存信息，目前页面显示信息可能有误，是否刷新页面？
      </span>
      <div class="err-tips">
        注意：<br />
        1.若用户处于‘制作答题卡’，‘参数选择’，‘匹配试题’等页面，请点击‘取消’按钮，在当前浏览器新打开的窗口切换为需要处理的学校，
        保存好信息在进行其他操作，否则刷新页面可能导致数据消失；<br />
        2.若用户正处于考试详情，例如，‘模板设置’，‘扫描管理’，‘进度监控’等页面，请刷新页面后退出到考试列表页面重新进入后操作。
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="closeFn()">关闭当前窗口</el-button> -->
        <el-button type="danger" plain @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="checkFn()"> 刷新页面 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "RefreshPage",
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },
    checkFn() {
      window.location.reload();
    },
    closeFn() {
      window.parent.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.refreshPage {
  .err-tips {
    color: red;
    text-align: justify;
  }
}
</style>
