import { app } from "../../../main";
import { MessageBox } from "element-ui";
import router from "@/router/index";
export default {
  state: {
    plusMinusStatus: 1,
    totalScore: null,
    clipImage: false,
    colorList: [
      {
        name: "白色",
        value: "rgba(252,252,252)",
      },
      {
        name: "空灰",
        value: "rgba(231,237,239)",
      },
      {
        name: "浅黄",
        value: "rgba(246,239,216)",
      },
      {
        name: "青绿",
        value: "rgba(190,225,197)",
      },
      {
        name: "蓝灰",
        value: "rgba(191,210,234)",
      },

      {
        name: "豆沙",
        value: "rgba(225,195,190)",
      },
      {
        name: "灰米",
        value: "rgba(168,175,185)",
      },
      {
        name: "曜黑",
        value: "rgba(13,36,66)",
      },
    ],
    blendColor: "",
    // blendColor: "rgba(252,252,252)",
    singleData: null,
    gradeList: [],
    stepType: "",
    bindingData: [],
    bindGradeList: [],
    imgList: [],
    commonData: {},
    stepSetting: null,
    bindStep: null,
    scoreTypePayload: 1,
  },
  mutations: {
    INIT_GRADELIST(state) {
      state.gradeList = [];
      state.commonData = {};
      state.stepType = "";
      state.stepSetting = null;
      state.bindGradeList = [];
      state.bindingData = [];
      state.bindStep = null;
    },
    SET_CLIPIMAGE(state, payload) {
      state.clipImage = payload;
    },
    SET_COMMONDATA(state, payload) {
      state.commonData = payload;
    },
    SET_IMGLIST(state, payload) {
      state.imgList = payload;
    },
    SET_TOTALSCORE(state, payload) {
      state.totalScore = payload;
    },
    SET_BLENDCOLOR(state, payload) {
      state.blendColor = payload;
    },
    SET_SINGLEDATA(state, payload) {
      state.singleData = payload;
    },
    SET_BINDINGDATA(state, payload) {
      state.bindingData = payload;
    },
    SET_GRADELIST(state, payload) {
      state.gradeList = payload;
    },
    SET_SCORETYPE(state, payload) {
      // 设置打分方式
      state.scoreTypePayload = payload;
    },
    SET_BINDGRADELIST(state, payload) {
      state.bindGradeList = payload;
    },
    SET_STEPTYPE(state, payload) {
      state.stepType = payload;
    },
    SET_STEPSETTING(state, payload) {
      state.stepSetting = payload;
    },
    SET_BINDSTEP(state, payload) {
      state.bindStep = payload;
    },
  },
  actions: {
    setScoreStep({ state, commit }, { payload, sheetDetailId }) {
      if (payload) {
        commit("SET_SINGLEDATA", payload);
      }
      let data = state.singleData ? state.singleData : payload;
      let stepSetting = state.stepSetting;
      let stepType = data.markStep;
      let markScore = String(data.markScore);

      if (payload && data.markType === 3) {
        let pointItem = payload.pointList.find(
          (item) => item.sheetDetailId == sheetDetailId
        );
        markScore = pointItem.markScore;
        state.singleData.markScore = Number(pointItem.markScore);
      }
      // 如果有自定义打分点
      if (stepSetting) {
        stepType = stepSetting.stepType;
        markScore =
          stepSetting.stepType === 1 ? stepSetting.step : stepSetting.stepPoint;
      }
      if (stepType == 1) {
        if (markScore.indexOf(",") == -1) {
          let arr = new Array(Math.ceil(data.score / markScore) + 1).fill(0);
          let markScoreNum = Number(markScore) * 100;
          let gradeList = arr.map((item, index) => {
            if ((index * markScoreNum) / 100 > data.score) {
              return data.score;
            }
            const itemData = (index * markScoreNum) / 100;
            return itemData;
          });
          if (gradeList.length > 80) {
            app.$toast.show(
              "当前步长下打分按钮已超过80个，建议使用计算器打分面板",
              3000
            );
            commit("SET_SCORETYPE", 2);
          }
          commit("SET_GRADELIST", gradeList);
          commit("SET_STEPTYPE", stepType);
        } else {
          commit("SET_GRADELIST", markScore.split(","));
          commit("SET_STEPTYPE", stepType);
        }
      } else if (stepType == 2) {
        let gradeList = markScore.split(",").map(Number);
        if (gradeList.length > 80) {
          commit("SET_SCORETYPE", 2);
          app.$toast.show(
            "当前步长下打分按钮已超过80个，建议使用计算器打分面板",
            3000
          );
        }
        commit(
          "SET_GRADELIST",
          gradeList.filter((item) => ![null, undefined, NaN].includes(item))
        );
        commit("SET_STEPTYPE", stepType);
      }
    },
    setBindingStep({ state, commit }, payload) {
      if (payload) {
        commit("SET_BINDINGDATA", payload);
      }
      let data = state.bindingData.length ? state.bindingData : payload;
      let stepSetting = state.bindStep;
      let bindGradeList = state.commonData.questionNumList.map(
        (item, index) => {
          let itemData = data.find((it) => it.questionNum == item);
          if (!itemData) {
            MessageBox.alert("题号已被修改，请重新进入阅卷", "提示", {
              confirmButtonText: "返回列表",
              callback: () => {
                router.replace({ path: "/mark/mark-list" });
              },
            });
            return false;
          }
          let stepType = state.commonData.markStepList[index];
          let markScore = state.commonData.markScoreList[index];
          let gradeList = [];
          if (stepSetting && stepSetting[index].stepType) {
            stepType = stepSetting[index].stepType;
            markScore =
              stepSetting[index].stepType === 1
                ? stepSetting[index].step
                : stepSetting[index].stepPoint;
          }
          if (stepType == 1) {
            let arr = new Array(
              Math.ceil(state.commonData.scoreList[index] / markScore) + 1
            ).fill(0);
            let markScoreNum = Number(markScore) * 100;
            gradeList = arr.map((it, ind) => {
              if (
                (ind * markScoreNum) / 100 >
                state.commonData.scoreList[index]
              ) {
                return state.commonData.scoreList[index];
              }
              const itemData = (ind * markScoreNum) / 100;
              return itemData;
            });
          } else if (stepType == 2 || stepType == 3) {
            if (markScore.indexOf(",") != -1) {
              gradeList = markScore.split(",").map(Number);
            } else if (markScore.indexOf("，") != -1) {
              gradeList = markScore.split("，").map(Number);
            } else {
              gradeList = [markScore].map(Number);
            }
          }
          return {
            ...itemData,
            questionNum:
              state.commonData.markType == 3
                ? state.commonData.questionNum +
                  "-" +
                  state.commonData.questionNumList[index]
                : state.commonData.questionNumList[index],
            examPaperId: itemData.examPaperId,
            stepType: stepType,
            markScore: markScore,
            fullScore: state.commonData.scoreList[index],
            gradeList: gradeList.filter((item) => item !== null && item !== ""),
            arbitrationScore: itemData.arbitrationScore,
            score:
              itemData.studentScore || itemData.studentScore === 0
                ? itemData.studentScore
                : null,
            open: true,
            avg: null,
          };
        }
      );
      // console.log(bindGradeList);
      commit("SET_BINDGRADELIST", bindGradeList);
    },
  },
};
