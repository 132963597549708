import { validatenull } from "./validate";
import request from "@/core/services/axios";
import * as CryptoJS from "crypto-js";
import router from "@/router";
import { subjectOptions } from "@/core/util/constdata.js";
import { Message } from "element-ui";
import { getStore } from "@/core/util/store";
import { getClientVersion } from "@/core/api/exam/examScanSheet";
import store from "@/core/services/store";
import moment from "moment";
import { dashboardRouter } from "@/core/util/constdata";
//转化颜色
export const isExpiredForPath = () => {
  const userInfo = getStore({ name: "userInfo" });
  let { roleId, teacherRoleType } = userInfo;
  const schoolInfo = getStore({ name: "schoolInfo" });
  if (schoolInfo) {
    if (schoolInfo.expireTime) {
      const nowTime = moment(schoolInfo.systemDate).valueOf();
      const expireTime = moment(schoolInfo.expireTime).valueOf();
      if (nowTime > expireTime) {
        if (roleId == 5) {
          let roleList = dashboardRouter;
          let roleItem = [];
          roleList.map((item) => {
            if (teacherRoleType.indexOf(item.roleId) != -1) {
              roleItem.push(item);
            }
          });
          return roleItem[0].url;
        } else {
          return "/dashboard/admin";
        }
      }
    }
  }
  return null;
};
export const getHexColor = (color) => {
  // 传的color须为字符串
  var values = color
    .replace(/rgba?\(/, "") // 把 "rgba(" 去掉，变成  "194, 7, 15, 1)"
    .replace(/\)/, "") // 把 ")" 去掉，变成 "194, 7, 15, 1"
    .replace(/[\s+]/g, "") // 把空格去掉，变成 "194,7,15,1"
    .split(","); // 变成数组 [194,7,15,1]
  var a = parseFloat(values[3] || 1), // values[3]是rgba中的a值，没有的话设置a为1，a可能为小数，所以用parseFloat函数
    r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255), // 转换为16进制
    g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
  return (
    "#" +
    ("0" + r.toString(16)).slice(-2) + // 转换后的16进制可能为一位，比如 7 就转换为 7 ， 15 转换为 f
    ("0" + g.toString(16)).slice(-2) + // 当为一位的时候就在前面加个 0，
    ("0" + b.toString(16)).slice(-2)
  ); // 若是为两位，加 0 后就变成了三位，所以要用 slice(-2) 截取后两位
};
export const hasValue = (value) => {
  // 判断数据是否不为null 或者 undefined 但是可以为0
  return value || String(value) === "0";
};
export const imgTime = (url, time) => {
  // let urlStr = url || "";
  const date = new Date().getTime();
  if (url) {
    let type = url.indexOf("?");
    if (type == -1) {
      if (time) {
        url = url + "?" + time;
      } else {
        url = url + "?time=" + date;
      }
    } else {
      if (time) {
        url = url + "&" + time;
      } else {
        url = url + "&time=" + date;
      }
    }
  } else {
    url = "";
  }
  return url;
};
export const downClient = () => {
  getClientVersion().then((res) => {
    // const { href } = router.resolve({
    //   name: "downFile",
    //   query: {
    //     url: res.data.data.link,
    //     type: 1,
    //     name: res.data.data.name,
    //   },
    // });
    // window.open(href, "_blank");
    window.open(res.data.data.link, "_blank");
  });
};
// 函数节流
export const throttle = (fn, delay = 200) => {
  let timer = null;
  return function () {
    if (timer) return;
    timer = setTimeout(() => {
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
};
// 判断是否是pc

export const IsPC = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flagPc = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flagPc = false;
      break;
    }
  }
  return flagPc;
};

// 表单序列化
export const serialize = (data) => {
  const list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};
export const getObjType = (obj) => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close;
  var o1 = obj1 instanceof Object;
  var o2 = obj2 instanceof Object;
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object;
    var t2 = obj2[attr] instanceof Object;
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } else if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
};
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = document.body.className + " grayMode";
  } else {
    document.body.className = document.body.className.replace(" grayMode", "");
  }
};
/**
 * 设置主题
 */
export const setTheme = (name) => {
  document.body.className = name;
};

/**
 *加密处理
 */
export const encryption = (params) => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === "Base64") {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele) => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback();
  }

  document.addEventListener("fullscreenchange", function () {
    listen();
  });
  document.addEventListener("mozfullscreenchange", function () {
    listen();
  });
  document.addEventListener("webkitfullscreenchange", function () {
    listen();
  });
  document.addEventListener("msfullscreenchange", function () {
    listen();
  });
};
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return (
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  );
};

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
};
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen();
  }
};
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i];
        } else {
          if (menu[i].children[j].children.length !== 0) {
            return findParent(menu[i].children[j].children, id);
          }
        }
      }
    }
  }
};

/**
 * 动态插入css
 */

export const loadStyle = (url) => {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = url;
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(link);
};
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true;
  Object.keys(a).forEach((ele) => {
    const type = typeof a[ele];
    if (type === "string" && a[ele] !== b[ele]) result = false;
    else if (
      type === "object" &&
      JSON.stringify(a[ele]) !== JSON.stringify(b[ele])
    )
      result = false;
  });
  return result;
};
/**
 * 根据字典的value显示label
 */
export const findByValue = (dic, value) => {
  let result = "";

  if (validatenull(dic)) return value;
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  ) {
    let index = 0;
    index = findArray(dic, value);
    if (index !== -1) {
      result = dic[index].label;
    } else {
      result = value;
    }
  } else if (value instanceof Array) {
    result = [];
    let index = 0;
    value.forEach((ele) => {
      index = findArray(dic, ele);
      if (index !== -1) {
        result.push(dic[index].label);
      } else {
        result.push(value);
      }
    });
    result = result.toString();
  }

  return result;
};
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i;
    }
  }
  return -1;
};
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = "";
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4);
  if (date) random = random + Date.now();
  return random;
};

export function handleDown(filename, bucket) {
  return request({
    url: "/admin/sys-file/" + bucket + "/" + filename,
    method: "get",
    responseType: "blob",
  }).then((response) => {
    // 处理返回的文件流
    const blob = response.data;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}

export function getQueryString(url, paraName) {
  const arrObj = url.split("?");
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split("&");
    let arr;
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");
      // eslint-disable-next-line eqeqeq
      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
  }
  return "";
}

export function toDate(date) {
  if (date instanceof Date) {
    return date;
  } else if (typeof date === "string") {
    if (date.indexOf("T")) {
      return new Date(date);
    } else if (date.length > 9) {
      return new Date(date.replace(/-/g, "/"));
    } else {
      return null;
    }
  } else if (date instanceof Number) {
    return new Date(date);
  } else {
    return null;
  }
}

export function dateFormat(date, fmt) {
  fmt = fmt || "yyyy-MM-dd HH:mm:ss";
  date = toDate(date);
  if (!date) {
    return "";
  }
  let ret;
  const opt = {
    "y+": date.getFullYear().toString(), // 年
    "Y+": date.getFullYear().toString(), // 年
    "M+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "m+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    "s+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

/*
 * 将指定数据从源数组中删除
 */
export function delArrFromArr(sourceArr, deletedArr) {
  let bakSourceArr = new Array().concat(sourceArr);
  sourceArr.length = 0;
  let deletedArrStr = "#" + deletedArr.join("#") + "#";
  for (let index in bakSourceArr) {
    let tempStr = "#" + bakSourceArr[index] + "#";
    // 如果需要删除，那么直接继续
    if (deletedArrStr.indexOf(tempStr) > -1) {
      continue;
    }
    // 如果不需要删除，那么加回来
    sourceArr.push(bakSourceArr[index]);
  }

  return sourceArr;
}

/*
 * 将指定数据添加进源数组，确保该值之前不在
 */
export function pushArrToArrByUnique(sourceArr, targetArr) {
  let sourceArrStr = "#" + sourceArr.join("#") + "#";
  for (let index in targetArr) {
    // 如果已存在，那么就忽略
    if (sourceArrStr.indexOf("#" + targetArr[index] + "#") > -1) {
      continue;
    }
    sourceArr.push(targetArr[index]);
  }
}

// 导出文件下载
export function exportFile(url) {
  // var evt;
  // const link = document.createElement("a");
  const { href } = router.resolve({
    name: "downFile",
    query: {
      url: url,
    },
  });
  window.open(href, "_blank");
}
// 存在名字的zip下载
export function fileNameByUrl(url, name) {
  let fileUrl = url;
  let fileName = name;
  let urlList = url.split("?")[0].split(".");
  let typeUrl = urlList[urlList.length - 1];
  // 判断下后缀
  if (!fileName) {
    let arr = url.split("/");
    //后缀可能有时间错  强行去掉
    fileName = arr[arr.length - 1].split("?")[0];
  }
  if (fileName.lastIndexOf(typeUrl) < 0) {
    fileName = fileName + "." + typeUrl;
  }
  // console.log(fileName);
  let req = new XMLHttpRequest();
  req.open("GET", fileUrl, true);
  req.responseType = "blob";
  req.onload = function () {
    let url = window.URL.createObjectURL(req.response);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  };
  req.send();
}
/*
 * 模板文件下载
 */
export function fileDownloadByUrl(url, name) {
  const link = document.createElement("a");
  let urlList = url.split("?")[0].split(".");
  let typeUrl = urlList[urlList.length - 1];
  if (typeUrl == "pdf" || typeUrl == "zip") {
    if (typeUrl == "zip") {
      let evt;
      link.setAttribute("href", url);
      evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, true);
      link.dispatchEvent(evt);
      link.click();
    } else {
      const { href } = router.resolve({
        name: "downFile",
        query: {
          url: url,
        },
      });
      window.open(href, "_blank");
    }
  } else {
    if (document.fireEvent) {
      link.setAttribute("href", url);
      window.open(url);
    } else {
      let fileUrl = url;
      let fileName = name;
      // console.log(fileName);
      // console.log(typeUrl);
      // 判断下后缀
      if (!fileName) {
        let arr = url.split("/");
        //后缀可能有时间错  强行去掉
        fileName = arr[arr.length - 1].split("?")[0];
      }
      if (fileName.lastIndexOf(typeUrl) < 0) {
        fileName = fileName + "." + typeUrl;
      }
      // console.log(fileName);
      let req = new XMLHttpRequest();
      req.open("GET", fileUrl, true);
      req.responseType = "blob";
      req.onload = function () {
        let url = window.URL.createObjectURL(req.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      req.send();
    }
  }
}

//获取cookie
export function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return arr[2];
  else return null;
}
//设置cookie,增加到vue实例方便全局调用
export function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
}
//删除cookie
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

//根据学段过滤科目
export function filterSubject(level, type) {
  //传入学段  判断返回当前学段的值
  let subjectArr = [];

  if (level == 2) {
    subjectArr = subjectOptions.filter((item) => item.value != 6);
  } else if (level == 3) {
    subjectArr = subjectOptions.filter((item) => item.value != 11);
  } else if (level == 1) {
    if (type == "questionType") {
      subjectArr = [
        {
          value: 1,
          label: "语文",
          index: 1,
        },
        {
          value: 2,
          label: "数学",
          index: 2,
        },
        {
          value: 3,
          label: "英语",
          index: 3,
        },
        // {
        //   value: 10,
        //   label: "科学",
        //   index: 4,
        // },
        // { value: 11, label: "道德与法治", index: 5 },
      ];
    } else {
      subjectArr = subjectOptions.filter((item) => item.value != 6);
    }
  } else {
    subjectArr = subjectOptions;
  }
  return [...subjectArr];
}
export const getLevelSubject = () => {
  let subjuect = getStore({ name: "schoolSubjectList" });
  const primarySubject = subjuect.filter((item) => item.value != 6); //小学科目
  const juniorSubject = subjuect.filter((item) => item.subjectId != 6); // 初中科目
  const highSubject = subjuect.filter((item) => item.subjectId != 11); // 高中中科目
  return {
    subjuect: subjuect,
    primarySubject: primarySubject,
    juniorSubject: juniorSubject,
    highSubject: highSubject,
  };
};

//过滤时间中返回的T
export const timeToStr = (val) => {
  if (!val) {
    return "-";
  }
  let nowTime = new Date().getTime();
  let schoolTime = new Date(val).getTime() + 24 * 60 * 60 * 1000;
  if (schoolTime - nowTime < 0) {
    return "已过期";
  }
  if (
    schoolTime - nowTime > 0 &&
    schoolTime - nowTime < 100 * 365 * 24 * 60 * 60 * 1000
  ) {
    return val;
  }
  if (schoolTime - nowTime > 100 * 365 * 24 * 60 * 60 * 1000) {
    return "永久有效";
  }
};
// 多选level获取学科
export function getSubjectListByLevelArr(levelArr) {
  const { subjuect, primarySubject, juniorSubject, highSubject } =
    getLevelSubject();
  const dataList = [primarySubject, juniorSubject, highSubject, subjuect];
  if (levelArr.length) {
    const cache = levelArr.flatMap((item) => {
      return dataList[item - 1];
    });
    const filterSubject = () => {
      let map = new Map();
      // console.log(cache);
      for (let item of cache) {
        if (!map.has(item.value)) {
          map.set(item.value, item);
        }
      }
      return [...map.values()];
    };
    // console.log(filterSubject());
    return filterSubject();
  } else {
    return subjuect;
  }
}
//获取学科
export function getSubjectList(level) {
  //传入学段  判断返回当前学段的值
  let subjuect = getStore({ name: "schoolSubjectList" });
  if (level == 3) {
    subjuect = subjuect.filter((item) => item.subjectId != 11);
  }
  if (level == 2) {
    subjuect = subjuect.filter((item) => item.subjectId != 6);
  }
  if (level == 1) {
    subjuect = subjuect.filter((item) => item.subjectId != 6);
    // subjuect = [
    //   {
    //     value: 1,
    //     label: "语文",
    //     index: 1,
    //   },
    //   {
    //     value: 2,
    //     label: "数学",
    //     index: 2,
    //   },
    //   {
    //     value: 3,
    //     label: "英语",
    //     index: 3,
    //   },
    //   {
    //     value: 10,
    //     label: "科学",
    //     index: 4,
    //   },
    //   { value: 11, label: "道德与法治", index: 5 },
    // ];
  }
  return subjuect;
}
// 获取年份
export function getYear() {
  const nowYear = new Date().getFullYear();
  const arr = new Array(nowYear - 2014).fill("");
  // console.log(arr);
  arr.push("");
  let yearList = arr.map((item, index) => {
    return { label: index + 2015, value: index + 2015 };
  });
  return yearList.reverse();
}
//过滤难度
export function filterDifficulty(val) {
  let number = 0;
  if (!val && val != 0) {
    number = "";
  } else {
    if (val >= 0.8 && val < 1) {
      number = 0.9;
    }
    if (val >= 0.6 && val < 0.8) {
      number = 0.7;
    }
    if (val >= 0.4 && val < 0.6) {
      number = 0.5;
    }
    if (val >= 0.2 && val < 0.4) {
      number = 0.3;
    }
    if (val >= 0 && val < 0.2) {
      number = 0.1;
    }
  }
  return number;
}

export function copyText(item) {
  // 模拟 输入框
  var cInput = document.createElement("input");
  cInput.value = item.key;
  document.body.appendChild(cInput);
  cInput.select(); // 选取文本框内容

  // 执行浏览器复制命令
  // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
  // Input要在正常的编辑状态下原生复制方法才会生效

  document.execCommand("copy");

  Message({
    type: "success",
    message: item.name ? item.name : "复制成功",
  });
  // 复制成功后再将构造的标签 移除
  document.body.removeChild(cInput);
}

// 根据已有科目 过滤只显示固定科目
export function showSubject(item, type) {
  // console.log(store.getters.GET_MSUBJECTIDS);
  // console.log(store.getters.GET_QSUBJECTIDS);
  // console.log(item.subjectId);
  let returnType = true;
  if (type == 9) {
    if (store.getters.GET_MSUBJECTIDS.indexOf(item.subjectId) == -1) {
      returnType = false;
    }
  }
  if (type == 8) {
    if (store.getters.GET_QSUBJECTIDS.indexOf(item.subjectId) == -1) {
      returnType = false;
    }
  }
  // console.log(returnType);
  let userInfo = getStore({ name: "userInfo" });
  // console.log(userInfo);
  if (userInfo.roleId != 5) {
    returnType = true;
  }
  return returnType;
}

//大写字母
export function capitalNumberFn(num) {
  var arr1 = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  var arr2 = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
  ];
  if (!num || isNaN(num)) return "零";
  var english = num.toString().split("");
  var result = "";
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; // 倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十"); // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零+/g, "零"); // 合并中间多个零为一个零
  result = result.replace(/零亿/g, "亿").replace(/零万/g, "万"); // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/亿万/g, "亿"); // 将【亿万】换成【亿】
  result = result.replace(/零+$/, ""); // 移除末尾的零
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, "十");
  return result;
}

// 自制卡获取最大题号
export function getQuestionMax(productionData) {
  let paperContent = productionData || store.getters.GET_PRODUCTION;
  let num = 0;
  if (paperContent.questionItem && paperContent.questionItem.length > 0) {
    paperContent.questionItem.map((index) => {
      if (index.type == 5) {
        if (index.value > num) {
          num = index.value;
        }
        return;
      }
      if (index.type == 6) {
        return;
      }
      index.questionOrderList.map((item) => {
        // console.log(item);
        if (index.type == 1 || index.type == 2) {
          item.questionArray.map((i) => {
            if (i.value > num) {
              num = i.value;
            }
          });
        }
        if (index.type == 3 || index.type == 4) {
          if (item.value > num) {
            num = item.value;
          }
        }
      });
    });
  }
  // console.log(num);
  return num + 1;
  // console.log();
}

// 像素和px相互转换
//调用直接 new unitConversion().pxConversionMm(像素)
//        new unitConversion().mmConversionPx (毫米)
export function unitConversion() {
  /**
   * 获取DPI
   * @returns {Array}
   */
  this.conversion_getDPI = function () {
    var arrDPI = new Array();
    if (window.screen.deviceXDPI) {
      arrDPI[0] = window.screen.deviceXDPI;
      arrDPI[1] = window.screen.deviceYDPI;
    } else {
      var tmpNode = document.createElement("DIV");
      tmpNode.style.cssText =
        "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
      document.body.appendChild(tmpNode);
      arrDPI[0] = parseInt(tmpNode.offsetWidth);
      arrDPI[1] = parseInt(tmpNode.offsetHeight);
      tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI;
  };
  /**
   * px转换为mm
   * @param value
   * @returns {number}
   */
  this.pxConversionMm = function (value) {
    var inch = value / this.conversion_getDPI()[0];
    var c_value = inch * 25.4;
    //      console.log(c_value);
    return c_value;
  };
  /**
   * mm转换为px
   * @param value
   * @returns {number}
   */
  this.mmConversionPx = function (value) {
    var inch = value / 25.4;
    var c_value = inch * this.conversion_getDPI()[0];
    //      console.log(c_value);
    return c_value;
  };
}

export function adminRole() {
  let roleArr = [1, 3, 6, 25, 26, 27, 28, 29, 30, 36];
  let userInfo = getStore({ name: "userInfo" });
  if (roleArr.indexOf(userInfo.roleId) == -1) {
    return false;
  }
  return true;
}
// 临时代码
export function adminRuleRole() {
  let roleArr = [1, 3, 6, 25, 26, 27, 28, 29, 30, 36];
  let userInfo = getStore({ name: "userInfo" });
  if (roleArr.indexOf(userInfo.roleId) == -1) {
    return false;
  }
  return true;
}
