const normalTime = (time) => {
  if (time) {
    var odate = new Date();
    odate.setTime(time);
    var year = odate.getFullYear();
    var month =
      odate.getMonth() + 1 < 10
        ? "0" + (odate.getMonth() + 1)
        : odate.getMonth() + 1;
    var day = odate.getDate() < 10 ? "0" + odate.getDate() : odate.getDate();
    var hours =
      odate.getHours() < 10 ? "0" + odate.getHours() : odate.getHours();
    var minutes =
      odate.getMinutes() < 10 ? "0" + odate.getMinutes() : odate.getMinutes();
    var seconds =
      odate.getSeconds() < 10 ? "0" + odate.getSeconds() : odate.getSeconds();
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }
};
//key匹配list中的关键字, list需要匹配的数组, type：存在则需要返回为空的文字，不存在就不返回
const setName = (key, list, type) => {
  // console.log(list);
  let label;
  let newArr = list.filter((item) => item.value == key);
  if (newArr.length > 0) {
    label = newArr[0].label;
  } else {
    if (type) {
      label = "暂无" + type;
    }
  }
  return label;
};
const setNames = (key, list) => {
  if (key) {
    key = key.toString();
  } else {
    key = "";
  }
  let label = [];
  list.map((item) => {
    if (key.indexOf(item.value) != -1) {
      label.push(item.label);
    }
  });
  return label.join("丶");
};

const setTitle = (key, list) => {
  let label;
  let newArr = list.filter((item) => item.type == key);
  if (newArr.length > 0) {
    label = newArr[0].name;
  }
  return label;
};

const setNumber = (val) => {
  if (!val) {
    return "0.00 %";
  }
  let number = (val * 100).toFixed(2);
  return number + " %";
};

const setToFilexd = (val) => {
  if (!val) {
    return "0";
  }
  let number = (val * 100).toFixed(2);
  return number;
};

const endClass = (val) => {
  if (!val) {
    return "";
  }
  if (/[\u4e00-\u9fff]$/.test(val)) {
    return val;
  } else {
    return val;
    // return val + "班";
  }
};

const setFilexd = (val) => {
  if (!val) {
    return "0";
  }
  // console.log(val);
  let number = Number(val).toFixed(2);
  return number;
};

//过滤时间中返回的T
const setTimeStyle = (val) => {
  if (!val) {
    return "";
  }
  let time = val.replace("T", " ");
  let arr = time.split(" ")[0];
  return arr;
};

//大写字母
const capitalNumber = (num, type) => {
  var arr1 = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  if (type) {
    arr1[2] = "两";
  }
  var arr2 = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
    "十",
    "百",
    "千",
    "万",
    "十",
    "百",
    "千",
    "亿",
  ];
  if (!num || isNaN(num)) return "零";
  var english = num.toString().split("");
  var result = "";
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; // 倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十"); // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零+/g, "零"); // 合并中间多个零为一个零
  result = result.replace(/零亿/g, "亿").replace(/零万/g, "万"); // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/亿万/g, "亿"); // 将【亿万】换成【亿】
  result = result.replace(/零+$/, ""); // 移除末尾的零
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, "十");
  return result;
};

export default {
  normalTime,
  setName,
  setTitle,
  setTimeStyle,
  setNumber,
  capitalNumber,
  setToFilexd,
  setFilexd,
  setNames,
  endClass,
};
