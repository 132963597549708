// import { MessageBox } from "element-ui";
const state = {
  // 新建答题卡基本信息
  examInformation: {},
  // 新建答题卡信息选中情况
  sheetHeader: [],
  // 自制答题卡json
  productionSheetData: {},
  // 页面是否超出长度
  lengthOutOfRange: false,
  warning: {},
  indexQuestionObj: {},
};
const actions = {};
const mutations = {
  SET_EXAM_INFORMATION(state, val) {
    state.examInformation = val;
  },
  SET_SHEET_HEADER(state, val) {
    state.sheetHeader = val;
  },
  SEVA_PRODUCTION(state, val) {
    state.productionSheetData = Object.assign({}, val);
  },
  SET_INDEX_QUESTION(state, val) {
    // console.log(val);
    state.indexQuestionObj = JSON.parse(JSON.stringify(val));
  },
  SET_SCROOL(state, val) {
    document.getElementsByName(val)[0].scrollIntoView();
    document.documentElement.scrollTop =
      document.documentElement.scrollTop - 130;
  },
  async SET_PRODUCTION(state, val) {
    let sheetContent = val;
    if (
      state.productionSheetData &&
      state.productionSheetData.sheetHead &&
      state.productionSheetData.sheetHead.warning
    ) {
      state.warning = Object.assign(
        {},
        state.productionSheetData.sheetHead.warning
      );
    }

    if (sheetContent.questionItem) {
      // 先根据已有的试题拼接出试题组
      await getQuestionGroup(state, val);
      // getQuestionGrouPrepetition(state, val);
      // 设置试题位置
      await setQuestionLocation(state);
      // 再根据已有的试题组 拼接每一页显示的试题列表
      // examInformation.paperType 1 A4 | 2 B4 | 3 A3
      // examInformation.columnCount 答题卡显示几栏 1 firstColumn | A4只有一栏   A3最少两栏  2  secondColumn | 3  thirdColumn
    }
    // console.log(state.productionSheetData);
    // console.log("1111111111");
    state.productionSheetData = Object.assign({}, sheetContent);
  },
};
const getters = {
  GET_INDEX_QUESTION(state) {
    // console.log(val);
    return state.indexQuestionObj;
  },
  GET_LENGTH(state) {
    return state.lengthOutOfRange;
  },
  GET_EXAM_INFORMATION(state) {
    return state.examInformation;
  },
  GET_SHEET_HEADER(state) {
    return state.sheetHeader;
  },
  GET_PRODUCTION(state) {
    return state.productionSheetData;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};

// 根据已有试题计算高度 top 值  各种后台需要的参数
function setQuestionLocation(state) {
  state.lengthOutOfRange = false;
  // 抽出所有面 组装成一个新的数组
  let parameterArray = [];
  parameterArray.push(state.productionSheetData.frontPaper.firstContent);
  if (state.productionSheetData.frontPaper.secondContent) {
    parameterArray.push(state.productionSheetData.frontPaper.secondContent);
  }
  if (state.productionSheetData.frontPaper.thirdContent) {
    parameterArray.push(state.productionSheetData.frontPaper.thirdContent);
  }
  parameterArray.push(state.productionSheetData.blankPaper.firstContent);
  if (state.productionSheetData.blankPaper.secondContent) {
    parameterArray.push(state.productionSheetData.blankPaper.secondContent);
  }
  if (state.productionSheetData.blankPaper.thirdContent) {
    parameterArray.push(state.productionSheetData.blankPaper.thirdContent);
  }
  parameterArray.map((item, index) => {
    item.questionItem = [];
    item.residueHeight = item.defaultHeight - 1.5;
    if (index && state.productionSheetData.paperCnt) {
      item.residueHeight = item.residueHeight - 6;
    }
  });
  parameterArray = JSON.parse(JSON.stringify(parameterArray));
  // console.log(JSON.parse(JSON.stringify(parameterArray)));
  let index = 0;
  let indexOrder = 1;
  // if (parameterArray) {
  //   return;
  // }
  // 循环数组  获取当前试题的高度
  state.productionSheetData.questionItem.map((item) => {
    // 如果是选择题 可能出现一种情况  那就是需要分组 可能存在一种第一页未显示完成 需要在第二页补充显示的情况
    // 那么就需要判断除 上一页能显示多少  在下一页然后显示剩余的部分
    // console.log(parameterArray[index]);

    let paperContent = state.productionSheetData.paperContent;
    let questionData = Object.assign({}, item);
    questionData.questionType = false;
    questionData.height = 0;
    questionData.questionOrderItem = [];
    if (
      parameterArray[index].residueHeight < 10 &&
      index != parameterArray.length - 1
    ) {
      index++;
      // parameterArray[index].residueHeight =
      //   parameterArray[index].defaultHeight - 1.5;
    }
    // console.log(index);
    // console.log(item);
    // 每次循环  都先减去大题名的高度
    if (item.type != 6) {
      parameterArray[index].residueHeight =
        parameterArray[index].residueHeight - 5.6;
    } else {
      // 非作答区域减二
      parameterArray[index].residueHeight =
        parameterArray[index].residueHeight - 2;
    }

    // 创建一个数组 用来添加当前行的数组
    let groupArray = [];

    // 客观题 填空题
    if (item.type == 1 || item.type == 2) {
      questionData.height = 2;
      questionData.padding = 2;
      parameterArray[index].residueHeight =
        parameterArray[index].residueHeight - 2;
      questionData.top =
        parameterArray[index].defaultHeight -
        parameterArray[index].residueHeight -
        2;
      // 创建一个长度初始值
      let width = 0;
      let maxHeight = 0;
      // 如来判断是否可以添加
      let addType = false;
      // 判断下刚好到最后一道题了
      let endType = false;

      item.questionOrderList.map((orderItem, orderIndex) => {
        // 判断下组有没有超出页面的组最大宽度

        if (width + orderItem.width <= paperContent.width - 5) {
          // console.log(orderIndex);
          width = width + orderItem.width;

          if (orderItem.height > maxHeight) {
            maxHeight = orderItem.height;
          }
          orderItem.left = width - orderItem.width;
          orderItem.top =
            parameterArray[index].defaultHeight -
            parameterArray[index].residueHeight;
          groupArray.push(orderItem);
          addType = false;
          if (
            orderIndex == item.questionOrderList.length - 1 &&
            width < paperContent.width - 5
          ) {
            addType = true;
          }
        } else {
          addType = true;
          orderItem.left = 0;
          orderItem.top =
            parameterArray[index].defaultHeight -
            parameterArray[index].residueHeight +
            maxHeight;
          // 如果说刚好到最后一个小题 之后没有试题了
          if (orderIndex == item.questionOrderList.length - 1) {
            // console.log("刚好纸张到最后一题了");
            // console.log(groupArray);
            endType = true;
          }
        }

        if (addType) {
          // 如果最大高度不超过 当前纸张
          // console.log(maxHeight);
          // console.log(questionData.height);
          // console.log(parameterArray[index].residueHeight);
          // 如果说到了最后一项  没有达到最大高度  就直接添加
          let indexResidueHeight = parameterArray[index].residueHeight - 3.5;
          if (state.productionSheetData.paperCnt) {
            indexResidueHeight = indexResidueHeight - 6;
          }
          if (maxHeight < indexResidueHeight) {
            // console.log(questionData.questionOrderItem);
            questionData.height = questionData.height + maxHeight + 2;

            groupArray.map((Gitem) => {
              Gitem.top =
                parameterArray[index].defaultHeight -
                parameterArray[index].residueHeight;
              if (item.type == 2) {
                Gitem.top = Gitem.top + 2;
              }
            });
            // console.log(groupArray);
            // 就直接在当前页的剩余高度减去这个值
            parameterArray[index].residueHeight =
              parameterArray[index].residueHeight - maxHeight - 2;
            questionData.questionOrderItem =
              questionData.questionOrderItem.concat(groupArray);
          } else {
            // console.log(questionData.questionOrderItem);
            // 如果超过了当前页的最大高度 就换页
            if (index < parameterArray.length - 1) {
              // 如果下一组的宽度将超过当前页面的最大高度  那么就直接将下一组组添加到下一页去
              questionData.padding = parameterArray[index].residueHeight - 3;
              if (state.productionSheetData.paperCnt) {
                questionData.padding = questionData.padding - 6;
              }
              parameterArray[index].residueHeight = 0;
              parameterArray[index].questionItem.push(questionData);
              index = index + 1;
              // questionData = Object.assign({}, questionData);
              questionData = JSON.parse(JSON.stringify(questionData));
              questionData.top =
                parameterArray[index].defaultHeight -
                parameterArray[index].residueHeight;
              questionData.questionType = "add";
              questionData.padding = 2;
              questionData.questionOrderItem = [];
              questionData.height = maxHeight + 2 + 2;

              // 就直接在当前页的剩余高度减去这个值
              parameterArray[index].residueHeight =
                parameterArray[index].residueHeight - 2;

              groupArray.map((Gitem) => {
                Gitem.top =
                  parameterArray[index].defaultHeight -
                  parameterArray[index].residueHeight;
                if (item.type == 2) {
                  Gitem.top = Gitem.top + 2;
                }
              });
              parameterArray[index].residueHeight =
                parameterArray[index].residueHeight - maxHeight - 2;
              questionData.questionOrderItem =
                questionData.questionOrderItem.concat(groupArray);
            } else {
              // 如果是最后一页了  那么直接将residueHeight 设为负值
              parameterArray[index].residueHeight =
                parameterArray[index].residueHeight - maxHeight;
              questionData.questionOrderItem =
                questionData.questionOrderItem.concat(groupArray);
            }
          }
          if (orderIndex == item.questionOrderList.length - 1) {
            // 创建一个值  避免重复添加
            // console.log(questionData.height);
            let pushType = true;
            if (parameterArray[index].residueHeight > 0) {
              // questionData.padding = 2;
              parameterArray[index].questionItem.push(questionData);
              pushType = false;
            }
            if (index == parameterArray.length - 1) {
              if (pushType) {
                parameterArray[index].questionItem.push(questionData);
              }
              if (parameterArray[index].residueHeight < 0) {
                // 如果说最后一页的超长了  记录一下 用于前端页面提示
                state.lengthOutOfRange = true;
              }
            }
          }
          groupArray.length = 0;
          groupArray.push(orderItem);
          orderItem = Object.assign({}, orderItem);
          // // // 如果宽度够了  就提行
          width = orderItem.width;
          maxHeight = orderItem.height;
          // console.log(groupArray.length);
          if (groupArray.length == 1) {
            orderItem.left = 0;
            orderItem.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight +
              maxHeight;
          } else {
            orderItem.left = width - orderItem.width;
            orderItem.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight;
          }

          if (endType) {
            // console.log(questionData.questionOrderItem);
            let indexResidueHeight = parameterArray[index].residueHeight - 3;
            if (state.productionSheetData.paperCnt) {
              indexResidueHeight = indexResidueHeight - 6;
            }
            if (maxHeight < indexResidueHeight) {
              // console.log(maxHeight);
              questionData.padding = 2;
              questionData.height = questionData.height + maxHeight + 2;

              groupArray.map((Gitem) => {
                Gitem.top =
                  parameterArray[index].defaultHeight -
                  parameterArray[index].residueHeight;
                if (item.type == 2) {
                  Gitem.top = Gitem.top + 2;
                }
              });
              // 就直接在当前页的剩余高度减去这个值
              parameterArray[index].residueHeight =
                parameterArray[index].residueHeight - maxHeight - 2;
              questionData.questionOrderItem =
                questionData.questionOrderItem.concat(groupArray);
            } else {
              // 如果超过了当前页的最大高度 就换页
              if (index < parameterArray.length - 1) {
                // 如果下一组的宽度将超过当前页面的最大高度  那么就直接将下一组组添加到下一页去
                // parameterArray[index].questionItem.push(questionData);

                questionData.padding = parameterArray[index].residueHeight - 3;
                if (state.productionSheetData.paperCnt) {
                  questionData.padding = questionData.padding - 6;
                }
                index = index + 1;

                questionData = JSON.parse(JSON.stringify(questionData));
                // console.log(groupArray);
                questionData.top =
                  parameterArray[index].defaultHeight -
                  parameterArray[index].residueHeight;
                questionData.padding = 2;
                questionData.questionType = "add";
                // console.log(questionData);
                questionData.questionOrderItem = [];
                // console.log(maxHeight);
                questionData.height = maxHeight + 2 + 2;

                // 就直接在当前页的剩余高度减去这个值
                parameterArray[index].residueHeight =
                  parameterArray[index].residueHeight - 2;

                groupArray.map((Gitem) => {
                  Gitem.top =
                    parameterArray[index].defaultHeight -
                    parameterArray[index].residueHeight;
                  if (item.type == 2) {
                    Gitem.top = Gitem.top + 2;
                  }
                });
                parameterArray[index].residueHeight =
                  parameterArray[index].residueHeight - maxHeight - 2;
                questionData.questionOrderItem =
                  questionData.questionOrderItem.concat(groupArray);
                parameterArray[index].questionItem.push(questionData);
              } else {
                // 如果是最后一页了  那么直接将residueHeight 设为负值
                parameterArray[index].residueHeight =
                  parameterArray[index].residueHeight - maxHeight;
                questionData.questionOrderItem =
                  questionData.questionOrderItem.concat(groupArray);
                parameterArray[index].questionItem.push(questionData);
              }
            }
          }
        }
      });
      // parameterArray[index].residueHeight =
      //   parameterArray[index].residueHeight - 2;
      // console.log("33333333");
    }
    // 解答题 选做题 非作答区域
    if (item.type == 3 || item.type == 4) {
      // 减去顶部涂抹项的高度
      if (item.type == 4) {
        // 涂抹项到顶部的距离
        questionData.top =
          parameterArray[index].defaultHeight -
          parameterArray[index].residueHeight;
        parameterArray[index].residueHeight =
          parameterArray[index].residueHeight - 6;
      }
      item.questionOrderList.map((orderItem, orderIndex) => {
        delete orderItem.questionType;
        delete orderItem.isAdd;

        // 试题的原本高度
        if (!orderItem.allHeight) {
          orderItem.allHeight = orderItem.height;
        } else {
          orderItem.height = orderItem.allHeight;
        }

        let questionHeight = orderItem.allHeight;
        let addQuestionType = false;
        let addQuestion = true;
        for (let i = 0; i < 6; i++) {
          // console.log(i);
          // console.log(questionHeight);

          questionData = Object.assign({}, questionData);
          orderItem = Object.assign({}, orderItem);
          // 判断下  剩余值小于八  就不添加了
          if (!addQuestion || questionHeight < 8) {
            questionData.questionOrderItem = [];
            questionData.questionType = "add";
            return;
          }
          let indexResidueHeight = parameterArray[index].residueHeight - 3;

          if (state.productionSheetData.paperCnt) {
            indexResidueHeight = indexResidueHeight - 6;
          }

          if (i > 0) {
            questionData.questionOrderItem = [];
            questionData.questionType = "add";
            orderItem.contentKey = i - 1;
            if (addQuestionType == true) {
              orderItem.questionType = "add";
              orderItem.height = questionHeight;
              // if (orderItem.height < 10) {
              //   orderItem.height = orderItem.height + 10;
              // }
            }

            delete orderItem.isAdd;
          }
          if (indexResidueHeight > questionHeight) {
            // 就直接在当前页的剩余高度减去这个值
            orderItem.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight;
            parameterArray[index].residueHeight =
              parameterArray[index].residueHeight - orderItem.height;
            questionData.questionOrderItem =
              questionData.questionOrderItem.concat(orderItem);
            break;
          } else {
            // 如果已经是最后一页了  就继续添加
            if (index == parameterArray.length - 1) {
              orderItem.top =
                parameterArray[index].defaultHeight -
                parameterArray[index].residueHeight;
              questionData.questionOrderItem =
                questionData.questionOrderItem.concat(orderItem);
              parameterArray[index].residueHeight =
                parameterArray[index].residueHeight - questionHeight;
              break;
            } else {
              if (parameterArray[index].residueHeight > 20) {
                addQuestionType = true;
                // console.log("22222");
                orderItem.top =
                  parameterArray[index].defaultHeight -
                  parameterArray[index].residueHeight;
                // 试题显示的高度
                orderItem.height = parameterArray[index].residueHeight - 3;
                if (index == 0) {
                  orderItem.height = parameterArray[index].residueHeight - 4;
                }
                if (state.productionSheetData.paperCnt) {
                  orderItem.height = orderItem.height - 5;
                }

                // 设置剩余高度
                parameterArray[index].residueHeight = 0;
                // 已经向下一页添加
                orderItem.isAdd = true;

                questionHeight = questionHeight - orderItem.height;
                if (questionHeight < 8) {
                  delete orderItem.isAdd;
                  addQuestion = false;
                }
                // 将试题添加至当前页
                questionData.questionOrderItem =
                  questionData.questionOrderItem.concat(orderItem);
                parameterArray[index].questionItem.push(questionData);
                // 如果不存在添加 续题的内容的字段  就添加一个
                if (orderItem.extendArr.length < 2) {
                  orderItem.extendArr = [];
                  for (let i = 0; i < 5; i++) {
                    orderItem.extendArr.push({
                      content: "",
                    });
                  }
                }
                // if (orderItem.allHeight - orderItem.height < 15) {
                //   orderItem.allHeight = orderItem.allHeight + 5;
                // }
                questionData.padding = parameterArray[index].residueHeight - 3;
                if (state.productionSheetData.paperCnt) {
                  questionData.padding = questionData.padding - 6;
                }

                // 跳转到下一页
                index = index + 1;
              } else {
                addQuestionType = false;
                // console.log("111111111");
                // orderItem.allHeight = orderItem.height;
                questionData.padding = parameterArray[index].residueHeight - 3;
                if (state.productionSheetData.paperCnt) {
                  questionData.padding = questionData.padding - 6;
                }
                parameterArray[index].residueHeight = 0;
                parameterArray[index].questionItem.push(questionData);
                // 跳转到下一页
                index = index + 1;
              }
            }
          }
        }

        if (orderIndex == item.questionOrderList.length - 1) {
          parameterArray[index].questionItem.push(questionData);
        }
      });
    }
    // 作文题
    if (item.type == 5) {
      // if (state.productionSheetData.markType == 1) {
      //   parameterArray[index].residueHeight =
      //     parameterArray[index].residueHeight - 5.5;
      // }
      let width = state.productionSheetData.paperContent.width - 8;
      questionData.tabWidth = width / questionData.sizeRow;
      questionData.tabHeight = questionData.tabWidth;
      questionData.rowNum = parseInt(questionData.rowNum);
      questionData.sizeRow = parseInt(questionData.sizeRow);
      // questionData.rowNumber = 0;
      let rowNum = questionData.rowNum;
      let oldNum = questionData.rowNum;
      // console.log(rowNum);
      let indexResidueHeight = parameterArray[index].residueHeight - 4;
      if (state.productionSheetData.paperCnt) {
        indexResidueHeight = indexResidueHeight - 6;
      }
      let nums = rowNum * (questionData.tabHeight + 2) < indexResidueHeight;
      if (state.productionSheetData.markType == 1) {
        nums =
          rowNum * (questionData.tabHeight + 2) <
          parameterArray[index].residueHeight -
            4 -
            5.5 -
            (state.productionSheetData.paperCnt ? 5.5 : 0);
      }
      if (nums) {
        // console.log("222");
        questionData.top =
          parameterArray[index].defaultHeight -
          parameterArray[index].residueHeight;
        questionData.startRow = 0;
        questionData.rowNumber = rowNum;

        questionData.height = rowNum * (questionData.tabHeight + 2) + 4;

        if (state.productionSheetData.markType == 1) {
          questionData.height =
            rowNum * (questionData.tabHeight + 2) + 6 + 2 + 1;
        }
        parameterArray[index].questionItem.push(questionData);

        parameterArray[index].residueHeight =
          parameterArray[index].residueHeight -
          rowNum * (questionData.tabHeight + 2) -
          4;
        if (state.productionSheetData.markType == 1) {
          parameterArray[index].residueHeight =
            parameterArray[index].residueHeight - 5.5;
        }
        if (parameterArray[index].residueHeight < 10) {
          questionData.height =
            parameterArray[index].residueHeight + questionData.height - 2;
          parameterArray[index].residueHeight = 0;
        }
      } else {
        for (let i = 0; i < 6; i++) {
          // console.log(i);
          // console.log("22222222");
          let indexResidueHeight = parameterArray[index].residueHeight - 8;
          // if (state.productionSheetData.paperCnt) {
          //   indexResidueHeight = indexResidueHeight - 6;
          // }
          // 多加4 误差范围允许 其他学校要求  稍微紧致一点
          if (state.productionSheetData.paperCnt) {
            indexResidueHeight = indexResidueHeight + 2;
          } else {
            indexResidueHeight = indexResidueHeight + 4;
          }

          let rowNumber = parseInt(
            indexResidueHeight / (questionData.tabHeight + 2)
          );

          // 如果是先扫后阅  减去分数高度
          if (state.productionSheetData.markType == 1 && i == 0) {
            rowNumber = parseInt(
              (indexResidueHeight - 5.5 - 4) / (questionData.tabHeight + 2)
            );
          }
          // 如果说当前页放不下试题的情况
          if (rowNum >= rowNumber) {
            if (index < parameterArray.length - 1) {
              questionData.top =
                parameterArray[index].defaultHeight -
                parameterArray[index].residueHeight;
              questionData.rowNumber = rowNumber;
              questionData.height =
                parameterArray[index].residueHeight -
                3 -
                (state.productionSheetData.paperCnt ? 5.5 : 0);
              questionData.startRow = oldNum - rowNum;
              rowNum = rowNum - questionData.rowNumber;
              parameterArray[index].residueHeight = 0;
              parameterArray[index].questionItem.push(questionData);
              questionData = Object.assign({}, questionData);
              questionData.questionType = "add";
              index++;
              // console.log(questionData);
            } else {
              questionData.top =
                parameterArray[index].defaultHeight -
                parameterArray[index].residueHeight;
              questionData.startRow = oldNum - rowNum;
              questionData.rowNumber = rowNum;
              questionData.height = rowNum * (questionData.tabHeight + 2) + 4;
              parameterArray[index].residueHeight =
                parameterArray[index].residueHeight -
                rowNum * (questionData.tabHeight + 2) -
                4;
              // console.log(questionData);
              parameterArray[index].questionItem.push(questionData);
              break;
            }
          } else {
            if (rowNum == 0) break;
            questionData.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight;
            parameterArray[index].residueHeight =
              parameterArray[index].residueHeight -
              rowNum * (questionData.tabHeight + 2) -
              4;
            questionData.height = rowNum * (questionData.tabHeight + 2) + 4;
            questionData.startRow = oldNum - rowNum;
            questionData.rowNumber = rowNum;
            parameterArray[index].questionItem.push(questionData);
            rowNum = 0;
            // console.log(questionData);
            break;
          }
        }
      }

      // console.log();
    }
    // 非作答区域
    if (item.type == 6) {
      delete item.isAdd;
      item.order = indexOrder;

      if (item.height <= 5) {
        item.height = 5;
      }
      indexOrder++;
      // 试题的原本高度
      if (!item.allHeight) {
        item.allHeight = item.height;
      }
      let indexResidueHeight = parameterArray[index].residueHeight - 3;
      if (state.productionSheetData.paperCnt) {
        indexResidueHeight = indexResidueHeight - 6;
      }
      if (indexResidueHeight > item.allHeight) {
        // 就直接在当前页的剩余高度减去这个值
        item.top =
          parameterArray[index].defaultHeight -
          parameterArray[index].residueHeight;
        parameterArray[index].residueHeight =
          parameterArray[index].residueHeight - item.allHeight;

        parameterArray[index].questionItem.push(item);
      } else {
        // 如果已经是最后一页了  就继续添加
        if (index == parameterArray.length - 1) {
          item.top =
            parameterArray[index].defaultHeight -
            parameterArray[index].residueHeight;
          parameterArray[index].questionItem.push(item);
          parameterArray[index].residueHeight =
            parameterArray[index].residueHeight - item.height;
        } else {
          // 如果不是最后一页  先判断下剩余的高度是否可以添加试题  如果可以，就添加  不可以 就换页添加
          if (parameterArray[index].residueHeight > 20) {
            item.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight;
            // 试题显示的高度
            // item.height = parameterArray[index].residueHeight - 4;
            item.height = parameterArray[index].residueHeight - 3;
            if (index == 0) {
              item.height = parameterArray[index].residueHeight - 3.5;
            }
            if (state.productionSheetData.paperCnt) {
              item.height = parameterArray[index].residueHeight - 7.5;
            }
            // 设置剩余高度
            parameterArray[index].residueHeight = 0;
            // 已经向下一页添加
            item.isAdd = true;
            // 将试题添加至当前页

            // 如果不存在添加 续题的内容的字段  就添加一个
            if (!item.extendArr || item.extendArr.length < 2) {
              item.extendArr = [];
              item.extendArr.push({
                content: "",
              });
            }
            if (item.allHeight - item.height < 8) {
              delete item.isAdd;
            }
            parameterArray[index].questionItem.push(item);
            // 跳转到下一页
            index = index + 1;
            if (item.allHeight - item.height < 8) {
              // console.log(item.allHeight - item.height);
              return;
            }
            questionData = Object.assign({}, item);
            delete questionData.isAdd;
            // 添加标志  代表当前 大题  当前小题 追加到了下一个区域
            questionData.questionType = "add";
            // console.log(orderItem.extendArr);
            questionData.content = questionData.extendArr[0].content;
            questionData.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight;
            questionData.height = questionData.allHeight - questionData.height;

            parameterArray[index].residueHeight =
              parameterArray[index].residueHeight - questionData.height;
            parameterArray[index].questionItem.push(questionData);
          } else {
            // orderItem.allHeight = orderItem.height;
            questionData.padding =
              parameterArray[index].residueHeight -
              3 -
              (state.productionSheetData.paperCnt ? 6 : 0);
            // parameterArray[index].residueHeight = 0;
            parameterArray[index].questionItem.push(questionData);
            index = index + 1;
            // parameterArray[index].residueHeight =
            //   parameterArray[index].residueHeight - 2;
            item.top =
              parameterArray[index].defaultHeight -
              parameterArray[index].residueHeight;
            parameterArray[index].residueHeight =
              parameterArray[index].residueHeight - item.height;
            questionData = Object.assign({}, questionData);
            parameterArray[index].questionItem.push(item);
          }
        }
      }
    }
    // console.log("2222222");
  });
  // console.log(index);
  // 两页
  if (parameterArray.length == 2) {
    // JSON.parse(JSON.stringify)
    state.productionSheetData.frontPaper.firstContent = Object.assign(
      {},
      parameterArray[0]
    );
    state.productionSheetData.blankPaper.firstContent = Object.assign(
      {},
      parameterArray[1]
    );
  }
  // 4页
  if (parameterArray.length == 4) {
    state.productionSheetData.frontPaper.firstContent = Object.assign(
      {},
      parameterArray[0]
    );
    state.productionSheetData.frontPaper.secondContent = Object.assign(
      {},
      parameterArray[1]
    );
    state.productionSheetData.blankPaper.firstContent = Object.assign(
      {},
      parameterArray[2]
    );
    state.productionSheetData.blankPaper.secondContent = Object.assign(
      {},
      parameterArray[3]
    );
  }
  // 6页
  if (parameterArray.length == 6) {
    state.productionSheetData.frontPaper.firstContent = Object.assign(
      {},
      parameterArray[0]
    );
    state.productionSheetData.frontPaper.secondContent = Object.assign(
      {},
      parameterArray[1]
    );
    state.productionSheetData.frontPaper.thirdContent = Object.assign(
      {},
      parameterArray[2]
    );
    state.productionSheetData.blankPaper.firstContent = Object.assign(
      {},
      parameterArray[3]
    );
    state.productionSheetData.blankPaper.secondContent = Object.assign(
      {},
      parameterArray[4]
    );
    state.productionSheetData.blankPaper.thirdContent = Object.assign(
      {},
      parameterArray[5]
    );
  }
  parameterArray.map((item) => {
    if (item.residueHeight < 0) {
      // 如果说超长了  记录一下 用于前端页面提示
      state.lengthOutOfRange = true;
    }
  });

  // console.log(parameterArray);
  state.productionSheetData = JSON.parse(
    JSON.stringify(state.productionSheetData)
  );
  // console.log(state.productionSheetData);
}
// 获取试题组
function getQuestionGroup(state, val) {
  let sheetContent = val;
  // console.log(sheetContent.questionItem);
  let indexValue = 0;
  sheetContent.questionItem.map((item) => {
    if (!item.questionTitleArr) {
      item.questionTitleArr = [1, 2, 3];
    }
    // 客观题
    if (item.type == 1) {
      let data = {
        questionArray: [],
        width: "",
        height: "",
      };
      item.objectiveType = state.examInformation.objectiveType;
      let questionOrderList = [];
      if (item.objectiveType == 2) {
        item.questionGroup.forEach((index, indexKey) => {
          for (let i = 0; i < index.questionNum; i++) {
            // console.log(indexKey);
            indexValue = indexValue + 1;
            let ArrayData = {
              score: index.score,
              scoreMiss: index.scoreMiss,
              subType: index.subType,
              optionNum: index.optionNum,
              questionNum: Number(index.questionStartOrder) + i,
              value: indexValue,
              additionalQid: item.additionalQid,
            };
            if (i == 0) {
              ArrayData.groupName = index.groupName;
            }
            if (item.additionalQid) {
              ArrayData.questionNum =
                Number(index.questionStartOrder) + `(${i + 1})`;
            }
            // console.log(item.additionalQid);
            // 判断下下一项的选项个数是不是和上一项的相等 如果是就添加  不是就取消
            if (data.questionArray.length > 0) {
              if (
                data.questionArray[data.questionArray.length - 1].optionNum ==
                ArrayData.optionNum
              ) {
                data.questionArray.push(ArrayData);
              } else {
                // 添加当前项长度
                if (data.questionArray.length == 0) {
                  return;
                }
                data.width = 8 + data.questionArray[0].optionNum * 6.4;
                if (data.questionArray[0].optionNum == 1) {
                  data.width = 8 + 2 * 6.4;
                }
                if (item.additionalQid) {
                  data.width = data.width + 2;
                }
                data.height = data.questionArray.length * 3.8 + 2;
                questionOrderList.push(Object.assign({}, data));
                data.questionArray = [];
                data.questionArray.push(ArrayData);
              }
            } else {
              data.questionArray.push(ArrayData);
            }

            // 如果说数组长度等于 试题每组数量  就添加至新数组
            if (data.questionArray.length == item.questionGroupNum) {
              if (data.questionArray.length == 0) {
                return;
              }
              // 添加当前项长度
              data.width = 8 + data.questionArray[0].optionNum * 6.4;
              if (data.questionArray[0].optionNum == 1) {
                data.width = 8 + 2 * 6.4;
              }
              if (item.additionalQid) {
                data.width = data.width + 2;
              }
              data.height = data.questionArray.length * 3.8 + 2;
              questionOrderList.push(Object.assign({}, data));
              data.questionArray = [];
            }
            // 如果说已经到最后一个循环了  但是长度不够添加的数据的时候  就直接添加至新数组
            if (
              i == index.questionNum - 1 &&
              indexKey == item.questionGroup.length - 1
            ) {
              // 添加当前项长度
              if (data.questionArray.length == 0) {
                return;
              }
              data.width = 8 + data.questionArray[0].optionNum * 6.4;
              if (data.questionArray[0].optionNum == 1) {
                data.width = 8 + 2 * 6.4;
              }
              if (item.additionalQid) {
                data.width = data.width + 2;
              }
              data.height = data.questionArray.length * 3.8 + 2;
              questionOrderList.push(Object.assign({}, data));
              data.questionArray = [];
              //
            }
          }
        });
      }
      if (item.objectiveType == 3) {
        item.questionGroup.forEach((index, indexKey) => {
          for (let i = 0; i < index.questionNum; i++) {
            indexValue = indexValue + 1;
            let ArrayData = {
              score: index.score,
              scoreMiss: index.scoreMiss,
              subType: index.subType,
              optionNum: index.optionNum,
              questionNum: Number(index.questionStartOrder) + i,
              value: indexValue,
              additionalQid: item.additionalQid,
            };
            if (i == 0) {
              ArrayData.groupName = index.groupName;
            }
            if (item.additionalQid) {
              ArrayData.questionNum =
                Number(index.questionStartOrder) + `(${i + 1})`;
            }
            // 判断下下一项的选项个数是不是和上一项的相等 如果是就添加  不是就取消
            if (data.questionArray.length > 0) {
              if (
                data.questionArray[data.questionArray.length - 1].optionNum ==
                ArrayData.optionNum
              ) {
                data.questionArray.push(ArrayData);
              } else {
                // 添加当前项长度
                if (data.questionArray.length == 0) {
                  return;
                }
                data.width = data.questionArray.length * 6.4 + 2;
                if (item.additionalQid) {
                  data.width = data.width + 2;
                }
                data.height = data.questionArray[0].optionNum * 4.3 + 5.8;
                if (data.questionArray[0].optionNum == 1) {
                  data.height = 2 * 4.3 + 5.8;
                }
                questionOrderList.push(Object.assign({}, data));
                data.questionArray = [];
                data.questionArray.push(ArrayData);
              }
            } else {
              data.questionArray.push(ArrayData);
            }

            // 如果说数组长度等于 试题每组数量  就添加至新数组
            if (data.questionArray.length == item.questionGroupNum) {
              if (data.questionArray.length == 0) {
                return;
              }
              // 添加当前项长度
              data.width = data.questionArray.length * 6.4 + 2;
              if (item.additionalQid) {
                data.width = data.width + 2;
              }
              data.height = data.questionArray[0].optionNum * 4.3 + 5.8;
              if (data.questionArray[0].optionNum == 1) {
                data.height = 2 * 4.3 + 5.8;
              }
              questionOrderList.push(Object.assign({}, data));
              data.questionArray = [];
            }
            // 如果说已经到最后一个循环了  但是长度不够添加的数据的时候  就直接添加至新数组
            if (
              i == index.questionNum - 1 &&
              indexKey == item.questionGroup.length - 1
            ) {
              // 添加当前项长度
              if (data.questionArray.length == 0) {
                return;
              }
              data.width = data.questionArray.length * 6.4 + 2;
              if (item.additionalQid) {
                data.width = data.width + 2;
              }
              data.height = data.questionArray[0].optionNum * 4.3 + 5.8;
              if (data.questionArray[0].optionNum == 1) {
                data.height = 2 * 4.3 + 5.8;
              }
              questionOrderList.push(Object.assign({}, data));
              data.questionArray = [];
              //
            }
          }
        });
      }

      item.questionOrderList = questionOrderList;
    }
    // 填空题
    if (item.type == 2) {
      let questionOrderList = [];
      item.questionGroup.forEach((index) => {
        for (let i = 0; i < index.questionNum; i++) {
          let data = {
            questionArray: [],
            width: "",
            height: 10,
          };
          indexValue = indexValue + 1;
          let ArrayData = {
            score: index.score,
            questionNum: Number(index.questionStartOrder) + i,
            value: indexValue,
            itemNumber: index.itemNumber,
            additionalQid: index.additionalQid,
          };
          if (index.additionalQid) {
            ArrayData.questionNum =
              Number(index.questionStartOrder) + `(${i + 1})`;
          }
          data.questionArray.push(ArrayData);
          data.width =
            (state.productionSheetData.paperContent.width - 15) /
            index.eachRowNumber;
          questionOrderList.push(Object.assign({}, data));
        }
      });
      item.questionOrderList = questionOrderList;
      // console.log(item);
      //
    }
    // 解答题 选做题
    if (item.type == 3 || item.type == 4) {
      item.questionOrderList.map((index) => {
        if (!index.questionTitleArr) {
          index.questionTitleArr = [1, 2, 3];
        }
        indexValue = indexValue + 1;
        index.value = indexValue;
        index.width = state.productionSheetData.paperContent.width - 5;
      });
    }
    // 作文题
    if (item.type == 5) {
      item.width = state.productionSheetData.paperContent.width - 5;
      item.questionNum = item.name;
      indexValue = indexValue + 1;
      item.value = indexValue;
    }
    // 非作答区域
    if (item.type == 6) {
      item.width = state.productionSheetData.paperContent.width - 5;
    }
  });
  // 将拼接好的数据先赋值
  state.productionSheetData = sheetContent;
}
// // 获取试题是否重复
// function getQuestionGrouPrepetition(state) {
//   // console.log(state.productionSheetData);
//   // let array = [];
//   let questionNum = [];
//   if (
//     state.productionSheetData.questionItem &&
//     state.productionSheetData.questionItem.length > 0
//   ) {
//     state.productionSheetData.questionItem.map((index) => {
//       if (index.type == 1 || index.type == 2) {
//         index.questionOrderList.map((item) => {
//           item.questionArray.map((i) => {
//             questionNum.push(i.questionNum);
//           });
//         });
//       }
//       if (index.type == 3 || index.type == 4) {
//         index.questionOrderList.map((item) => {
//           questionNum.push(item.questionNum);
//         });
//       }
//       if (index.type == 5) {
//         questionNum.push(index.questionNum);
//       }
//     });
//   }
//   let array = refrain(questionNum);
//   if (array.length > 0) {
//     MessageBox.alert(
//       `${array.join(",")}题题号重复,会导致答题卡无法保存,请修改!`,
//       "提示",
//       {
//         confirmButtonText: "确定",
//       }
//     );
//   }

//   // console.log(array);
//   // array=Array.from(new Set(questionNum))
//   // console.log(questionNum);
// }
// // 获取数组中重复的值
// function refrain(arr) {
//   let tmp = [];
//   if (Array.isArray(arr)) {
//     arr
//       .concat()
//       .sort()
//       .sort(function (a, b) {
//         if (a == b && tmp.indexOf(a) == -1) tmp.push(a);
//       });
//   }
//   return tmp;
// }
