import request from "@/core/services/axios";
export function exportAllReport(params) {
  // 普通考试-导出6个报告
  return request({
    url: `/report/statstudentreport/export/all`,
    method: "get",
    params: params,
  });
}
export function exportAllReportNew(params) {
  // 新高考-导出6个报告
  return request({
    url: `/report/statnewgaokaostudentreport/export/all`,
    method: "get",
    params: params,
  });
}

export function getstatreportstatisticsinfo() {
  // 获取统计报告列表页面顶部统计数据
  return request({
    url: `/report/stat/getstatreportstatisticsinfo`,
    method: "post",
  });
}
export function changestatstudentranksetting(params) {
  // 保存当前排名信息设置
  return request({
    url: `/report/stat/changestatstudentranksetting`,
    method: "post",
    data: params,
  });
}
export function getstatstudentrank(params) {
  // 保存当前排名信息设置
  return request({
    url: `report/stat/getstatstudentrank`,
    method: "get",
    params: params,
  });
}

export function reportpage(params) {
  // 统计报告分页查询
  return request({
    url: `/report/stat/reportpage`,
    method: "post",
    data: params,
  });
}
export function getcansplitsubjectlist(params) {
  // 拆分科目
  return request({
    url: `/report/statsubject/getcansplitsubjectlist`,
    method: "get",
    params: params,
  });
}

export function downloadstatpdfreport(params) {
  // 下载统计PDF报告
  return request({
    url: `/report/statreportinfo/downloadstatpdfreport`,
    method: "get",
    params: params,
  });
}
export function gettopinfo(params) {
  // 获取统计报告详情页顶部数据
  return request({
    url: `/report/statreportinfo/gettopinfo`,
    method: "get",
    params: params,
  });
}
export function getschoolreportsimple(params) {
  // 获取校级报告简单图表
  return request({
    url: `/report/statreportinfo/getschoolreportsimple`,
    method: "get",
    params: params,
  });
}

export function getbanzhurenreportsimple(params) {
  // 获取班主任报告简单图表
  return request({
    url: `/report/statreportinfo/getbanzhurenreportsimple`,
    method: "get",
    params: params,
  });
}
export function getstudentreportsimple(params) {
  // 获取学生报告简单图表
  return request({
    url: `/report/statreportinfo/getstudentreportsimple`,
    method: "get",
    params: params,
  });
}
export function getresearchreportsimple(params) {
  // 获取教研报告简单图表
  return request({
    url: `/report/statreportinfo/getresearchreportsimple`,
    method: "get",
    params: params,
  });
}
export function getrenkejiaoshireportsimple(params) {
  // 获取教研报告简单图表
  return request({
    url: `/report/statreportinfo/getrenkejiaoshireportsimple`,
    method: "get",
    params: params,
  });
}
export function getpaperreviewreportsimple(params) {
  // 获取试卷讲评简单图表
  return request({
    url: `/report/statreportinfo/getpaperreviewreportsimple`,
    method: "get",
    params: params,
  });
}
export function getpaperreviewreportsimpleV2(params) {
  // 单校-普通考试-报告首页  获取试卷讲评简单图表V2  接口替换为
  return request({
    url: `/report/statreportinfo/getpaperreviewreportsimple/v2`,
    method: "get",
    params: params,
  });
}
export function getnewgaokaoreportsimple(params) {
  // 新高考基础统计-基础指标汇总
  return request({
    url: `/report/statreportinfo/getnewgaokaoreportsimple`,
    method: "get",
    params: params,
  });
}
export function getstatuserauthteacherrole(params) {
  // 获取报告权限
  return request({
    url: `/report/stat/getstatuserauthteacherrole`,
    method: "get",
    params: params,
  });
}
export function getCard(params) {
  // 获取答题卡
  return request({
    url: `/report/statoneexamexport/getAnswerSheet`,
    method: "post",
    params: params,
  });
}
export function getStudentCard(params) {
  // 获取学生答题卡
  return request({
    url: `/report/statoneexamexport/getAnswerSheetV2`,
    method: "post",
    params: params,
  });
}
export function getReportExamclassnumList(params) {
  // 统计获取考试下班级
  return request({
    url: `/report/stat/getexamclassnumList`,
    method: "get",
    params: params,
  });
}
