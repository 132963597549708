<template>
  <div class="header">
    <div class="menu-box">
      <div class="menu">
        <div class="login-title">
          <div @click="toDashboard">
            <img
              src="@/assets/loginImg/new-login/logo-header.png"
              style="width: 114px"
              alt=""
            />
          </div>
        </div>

        <div
          v-if="dashboardItem.type"
          class="menu-list"
          style="width: 163px; text-align: center"
          :class="{
            on:
              dashboardItem.url == $route.path ||
              dashboardItem.url == $route.meta.name,
          }"
          @click="desLinkTo(dashboardItem)"
        >
          {{ getName() }}
          <template
            v-if="dashboardItem.childList && dashboardItem.childList.length > 0"
          >
            <i class="el-icon-arrow-down i-style"></i>
            <div class="child-list">
              <ul>
                <li
                  v-for="(m, n) in dashboardItem.childList"
                  :key="'chill' + n"
                  :class="{ childOn: m.url == $route.path }"
                  @click.stop="linkTo(m)"
                >
                  {{ m.name }}
                </li>
              </ul>
            </div>
          </template>
        </div>
        <template v-if="!isExpired">
          <div
            v-for="(v, i) in menuList"
            :key="i"
            class="menu-list"
            :class="{
              on:
                (v.childList && v.childList.length > 0) || v.heightLight
                  ? v.url == $route.meta.tabName
                  : v.url == $route.meta.name,
            }"
            @click="linkTo(v)"
          >
            {{ v.name }}
            <i
              v-if="v.childList && v.childList.length > 0"
              class="el-icon-arrow-down i-style"
            ></i>
            <!-- 子菜单 -->
            <div
              v-if="v.childList && v.childList.length > 0"
              class="child-list"
            >
              <!-- 构建一个三角形 -->
              <ul>
                <li
                  v-for="(m, n) in v.childList"
                  :key="'chill' + n"
                  :class="{ childOn: m.url == $route.path }"
                  @click.stop="linkTo(m)"
                >
                  {{ m.name }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
      <div class="operate">
        <!-- 学校版本：0-默认版 1-基础版 2-标准版 3-精准教学 -->
        <!--      trigger="" -->
        <!--  -->
        <div ref="tipsBox">
          <el-popover
            v-model="showTips"
            placement="bottom-start"
            popper-class="model-tips-class"
            :content="'到期时间：' + timeToStr(schoolInfo.expireTime)"
          >
            <div slot="reference">
              <img
                v-if="userInfo.schoolVersion == 2"
                class="versions-icon"
                src="@/assets/header/criterion_icon.png"
                alt=""
                @click="showVis(2)"
              />
              <img
                v-if="userInfo.schoolVersion == 1"
                class="versions-icon"
                src="@/assets/header/basics_icon.png"
                alt=""
                @click="showVis(1)"
              />
              <img
                v-if="userInfo.schoolVersion == 3"
                class="versions-icon"
                src="@/assets/header/study_icon.png"
                style="width: 86px"
                alt=""
                @click="showVis(3)"
              />
            </div>
          </el-popover>
        </div>
        <!-- <div
          v-if="userInfo.isDisplayWorkstand && userInfo.schoolVersion != 1"
          class="btn"
          @click="toDashboard"
        >
          工作台
        </div> -->
        <div class="user-box" @click="showBox">
          <div class="avatar">
            <img :src="avatar" alt="" />
          </div>
          <div class="user-intro">
            <p>{{ userName }}</p>
            <span>{{ userSchool }}</span>
          </div>
          <div v-if="clickOn" class="user_detail">
            <div class="user_info" @click="goto('userinfo')">
              <div class="user_name">个人中心</div>
            </div>
            <!-- {{ roleId }} -->
            <div
              v-if="oldSchoolId === 0"
              class="user_info user-btn"
              @click="checkSchool()"
            >
              <div>切换学校</div>
              <!-- <div @click="toAdmin">返回管理端</div> -->
            </div>
            <div class="logout" @click="onLogout">退出登录</div>
          </div>
        </div>
      </div>
    </div>
    <versionComparison ref="versionComparison"></versionComparison>
    <div v-if="isExpired" class="expiresTime">
      用户您好，平台的服务授权已到期，为确保您工作顺利展开，请及时联系
      400-607-6782 及时开通！
    </div>
  </div>
</template>

<script>
import { getStore, setStore, clearLogonStore } from "@/core/util/store";
import { adminUrl } from "@/config/evn";
import { LOGOUT } from "@/core/services/store/auth.module";
import { usercenter } from "@/core/api/login.js";
import { getRedirectUrl } from "@/core/api/school/school.js";
import Cookie from "js-cookie";

import {
  // schoolAllSubject,
  schoolSubject,
  // postSubject,
} from "@/core/api/newOther";
// import {  } from "@/core/util/store";
import versionComparison from "./versions/versionComparison";
import { mapGetters } from "vuex";
import { timeToStr } from "@/core/util/util";
import { getExamineList } from "@/core/api/exam/exampaper";
export default {
  components: {
    // modelTips,\
    versionComparison,
  },
  data() {
    return {
      showTips: false,
      versionUpdating: false,
      isExpired: false,
      menu: [],
      adminUrl: adminUrl,
      userName: "",
      userSchool: "",
      unionExam: "",

      clickOn: false,
      key: 0,
      dialogVisible: false,
      schoolListData: [],
      schoolListAllData: [],
      schoolId: 0,
      userId: 1,
      imgUrl: "https://static.wtjy.com/resource/avatar/",
      avatar: "",
      roleId: null,
      teacherId: null,
      oldSchoolId: "",
      schoolInfo: {},
      dashboardItem: {},
      userInfo: {},
      menuList: [],
    };
  },
  computed: {
    ...mapGetters(["GETBARLIST"]),
  },
  created() {
    this.initview();
    this.schoolSubject();
    window.addEventListener("mousedown", this.handleonmousedown);
    this.getAnalyze();
  },
  mounted() {
    setTimeout(() => {
      this.initTips();
    }, 500);
    setTimeout(() => {
      this.tipsMove();
    }, 3000);
  },
  destroyed() {
    window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    tipsMove() {
      // 获取按钮元素
      const div = this.$refs.tipsBox;
      if (!div) return;
      const _this = this;
      // 添加 mouseover 事件监听器
      div.addEventListener("mouseover", function () {
        // console.log("1111111111111");
        _this.showTips = true;
      });
      // 添加 mouseout 事件监听器
      div.addEventListener("mouseout", function () {
        _this.showTips = false;
      });
    },
    getName() {
      let str = "";
      if (this.$route.name == "/dashboard/admin") {
        str = "教务工作台";
      }
      if (
        this.dashboardItem.childList &&
        this.dashboardItem.childList.length > 0
      ) {
        this.dashboardItem.childList.map((item) => {
          if (item.url == this.$route.name) {
            str = item.name;
          }
        });
      }
      if (!str) {
        if (
          this.dashboardItem.childList &&
          this.dashboardItem.childList.length > 0
        ) {
          str = this.dashboardItem.childList[0].name;
        } else {
          str = this.dashboardItem.name;
        }
      }
      return str;
    },
    getAnalyze() {
      this.menuList = this.GETBARLIST;
      // let data = {
      //   name: "数智作业",
      //   url: "/homework/list",
      //   showTab: false,
      //   isClick: true,
      //   type: "examine",
      // };
      // this.menuList = this.menuList.concat(data);
      getExamineList().then((res) => {
        if (res.data.data.records && res.data.data.records.length > 0) {
          let data = {
            name: "审题",
            url: "/examine/list",
            showTab: false,
            isClick: true,
            type: "examine",
          };
          this.menuList = this.menuList.concat(data);
        } else {
          // this.menuList = this.menuList;
        }
      });
    },
    timeToStr(val) {
      return timeToStr(val);
    },
    initTips() {
      let schoolInfo = getStore({ name: "schoolInfo" });
      let time = "";
      let nowTime = new Date().getTime();
      if (schoolInfo.expireTime) {
        time = new Date(schoolInfo.expireTime).getTime();
      } else {
        time = new Date().getTime();
      }
      // console.log(schoolInfo);
      if (!schoolInfo.expireTime) {
        this.showTips = false;
        return;
      }

      if (time - nowTime < 1000 * 24 * 60 * 60 * 30) {
        this.showTips = true;
      }
      setTimeout(() => {
        this.showTips = false;
      }, 5000);
    },

    async initview() {
      await this.getBaseUserInfo();
      this.userId = getStore({ name: "userInfo" }).userId;
      this.avatar = getStore({ name: "userInfo" }).avatar;
      this.userInfo = getStore({ name: "userInfo" });
      this.teacherId = getStore({ name: "userInfo" }).teacherId;
      if (!this.avatar) {
        this.avatar = this.imgUrl + "131.jpg";
      }
      this.oldSchoolId = getStore({
        name: "oldSchoolId",
      });
      this.initDashboard();
    },
    initDashboard() {
      this.dashboardItem = {};
      if (
        !this.userInfo.isDisplayWorkstand ||
        this.userInfo.schoolVersion == 1 ||
        this.userInfo.schoolType == 2
      ) {
        return;
      }
      let { roleId, teacherRoleType } = this.userInfo;

      if (roleId != 4 || roleId != 5) {
        this.dashboardItem = {
          url: "/dashboard/admin",
          type: 1,
          name: "教务工作台",
        };
      }
      if (roleId == 5) {
        let roleList = [
          {
            url: "/dashboard/teacher",
            type: 1,
            name: "校领导工作台",
            roleId: 5,
          },
          {
            url: "/dashboard/grade/teacher",
            type: 1,
            name: "年级组长工作台",
            roleId: 4,
          },
          {
            url: "/dashboard/preparation/teacher",
            type: 1,
            name: "备课组长工作台",
            roleId: 3,
          },
          {
            url: "/dashboard/classLeader/teacher",
            type: 1,
            name: "班主任工作台",
            roleId: 2,
          },
          {
            url: "/dashboard/courseTeacher/teacher",
            type: 1,
            name: "任课教师工作台",
            roleId: 1,
          },
        ];
        let roleItem = [];
        roleList.map((item) => {
          if (teacherRoleType.indexOf(item.roleId) != -1) {
            roleItem.push(item);
          }
        });
        if (roleItem.length == 1) {
          this.dashboardItem = {
            url: roleItem[0].url,
            type: 1,
            name: roleItem[0].name,
          };
        }
        if (roleItem.length > 1) {
          this.dashboardItem = {
            url: "/dashboard",
            type: 1,
            childList: roleItem,
          };
        }
      }
    },
    // 显示
    handleonmousedown($event) {
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      if (str.indexOf("user-box") == -1) {
        this.clickOn = false;
      }
    },
    toDashboard() {
      let { schoolVersion, roleId, schoolType } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      if (roleId == 4 || schoolType == 2) {
        return;
      }
      if (schoolVersion != 1) {
        this.$router.push("/dashboard");
      }
    },
    checkSchool() {
      this.$router.push("/schoolList");
    },
    toWeb() {
      window.location.href = process.env.VUE_APP_WEB_URL;
    },
    // 获取当前学校的所有科目
    schoolSubject() {
      if (getStore({ name: "schoolSubjectList" })) {
        return;
      }
      schoolSubject().then((res) => {
        this.subjectIds = res.data.data.map((item) => item.subjectId);
        res.data.data.map((item) => {
          item.value = item.subjectId;
          item.label = item.subjectName;
        });
        setStore({ name: "schoolSubjectList", content: res.data.data });
      });
    },
    toAdmin() {
      // console.log(this.adminUrl);
      window.open(this.adminUrl, "_blank");
    },

    getBaseUserInfo() {
      // this.key = new Date().getTime();
      let userInfo = getStore({ name: "userInfo" });

      if (!userInfo.roleId) {
        usercenter().then((response) => {
          if (response.data.data.notification) {
            setStore({
              name: "notification",
              content: response.data.data.notification,
            });
          }
          if (response.data.data.schoolInfo) {
            setStore({
              name: "schoolInfo",
              content: response.data.data.schoolInfo,
            });
            this.schoolInfo = response.data.data.schoolInfo || {};
            let { systemDate, expireTime } = this.schoolInfo;
            if (systemDate) {
              const expireTimeVal =
                new Date(expireTime).getTime() + 86400 * 1000;
              const nowTime = new Date().getTime();
              this.isExpired = nowTime > expireTimeVal;
              this.$emit("isExpired", this.isExpired);
            }
          }
          if (response.data.data.userInfo) {
            Cookie.set(
              "userInfo",
              JSON.stringify(response.data.data.userInfo),
              {
                domain: ".wtjy.com",
              }
            );
            this.userName = response.data.data.userInfo.username;
            this.userSchool = response.data.data.userInfo.schoolName;
            this.$store.commit(
              "report/SET_ROLEID",
              response.data.data.userInfo.roleId
            );

            if (response.data.data.userInfo.roleId == 4) {
              this.userSchool =
                this.userSchool + " " + response.data.data.userInfo.gradeClass;
            }
            setStore({
              name: "user-message",
              content: response.data.data.userInfo,
            });

            setStore({
              name: "userInfo",
              content: Object.assign(userInfo, response.data.data.userInfo),
            });
            let routerList = JSON.parse(response.data.data.routerList);
            routerList.map((index) => {
              if (index.url == "/newOther/newOther") {
                index.heightLight = true;
              }
            });
            setStore({
              name: "router-list",
              content: JSON.stringify(routerList),
            });
            localStorage.setItem("isDisplay", response.data.data.isDisplay);
            const { applicationList, lastStatId, isDisplay } =
              response.data.data;
            this.isDisplay = isDisplay;

            // this.menu = routerList;

            this.$store.commit("SETBARLIST", routerList);
            this.$store.commit("setHeaderType");
            localStorage.setItem(
              "applicationList",
              JSON.stringify(applicationList)
            );
            localStorage.setItem("lastStatId", JSON.stringify(lastStatId));
          } else {
            this.schoolId = Number(getStore({ name: "school_id" }));
            this.schoolList();
          }

          // 如果存在更新内容
        });
      } else {
        this.$store.commit("report/SET_ROLEID", userInfo.roleId);
        this.userName = userInfo.username;
        this.userSchool = userInfo.schoolName;

        this.schoolInfo =
          getStore({
            name: "schoolInfo",
          }) || {};
        Cookie.set("userInfo", JSON.stringify(userInfo), {
          domain: ".wtjy.com",
        });
        let { systemDate, expireTime } = this.schoolInfo;

        if (systemDate) {
          const expireTimeVal = new Date(expireTime).getTime() + 86400 * 1000;
          const nowTime = new Date().getTime();
          this.isExpired = nowTime > expireTimeVal;

          this.$emit("isExpired", this.isExpired);
        }
        let routerList = getStore({ name: "router-list" });
        if (!routerList) {
          clearLogonStore();

          this.$router.push({ name: "login" });
          return;
        }
        // this.menu = ;
        this.$store.commit("SETBARLIST", JSON.parse(routerList));

        // this.userName = getStore({ name: "userInfo" }).username;
      }
      // console.log(userInfo);
    },
    showBox() {
      // setTimeout(() => {
      if (this.clickOn) {
        this.clickOn = false;
      } else {
        this.clickOn = true;
      }
      // }, 500);
    },
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        clearLogonStore();
        this.$router.push({ name: "login" });
      });
    },
    // 获取学科网跳转链接
    getUrl(v) {
      getRedirectUrl().then((res) => {
        // console.log(res);
        this.$router.push({
          path: v.url,
        });
        setStore({
          name: "xuekewang",
          type: 1,
          content: res.data.data,
        });
      });
    },
    desLinkTo(v) {
      if (v.childList && v.childList.length > 0) {
        return;
      }
      this.$router.push({
        path: v.url,
        query: v.query,
      });
    },
    linkTo(v) {
      if (!v.type) {
        return;
      }
      if (v.type == "AICourse") {
        // this.getUrl(v);
        let url = "https://live.k12top.com/auth/WenTong?token=";
        let userInfo = getStore({
          name: "userInfo",
        });
        //   console.log(userInfo);
        let thirdToken = userInfo.thirdToken;
        window.open(url + thirdToken);
        return;
      }
      if (v.type == "openWindow") {
        window.open(v.url);
        return;
      }
      if (v.url == "" || v.url == "analy") {
        return;
      }
      let userInfo = getStore({ name: "userInfo" });
      if (userInfo.schoolType === 2 && v.url === "/newOther/newOther") {
        this.$router.push({
          path: "/union/base/user",
        });
      } else {
        this.$router.push({
          path: v.url,
          query: v.query,
        });
      }
      // if (v.childList && v.childList.length > 0) {
      //   return;
      // }
      // if (v.name === "基础信息") {
      //   this.$router.push({
      //     path: "/union/base",
      //   });
      // } else {
      //   this.$router.push({
      //     path: v.url,
      //   });
      // }
    },
    goto(page) {
      if (page === "userinfo") {
        this.$router.push({
          name: "userinfo",
        });
      }
    },
    showVis(val) {
      let schoolInfo =
        getStore({
          name: "schoolInfo",
        }) || {};
      if (schoolInfo.type == 2) return;
      this.$refs.versionComparison.init(val);
    },
  },
};
</script>
<style lang="scss">
body {
  .model-tips-class {
    background: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 6px 10px;
    .popper__arrow::after {
      border-bottom-color: #303133 !important;
    }
  }
}
</style>
<style scoped lang="scss">
/* @import url(); 引入css类 */

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // min-width: 100vw;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
  border-bottom: 2px solid #dddddd;
  z-index: 999;
  height: 74px;
  // overflow-x: scroll;
  // overflow-y: hidden;
  // padding-left: calc(100vw - 100%);
  // margin-left: calc(100% - 100vw);

  .model-box {
    max-height: 300px;
    overflow-y: scroll;
  }

  .el-radio-list {
    width: 180px;
  }

  .input-with-select {
    width: 300px;
    margin-bottom: 20px;
  }

  .user_detail {
    position: absolute;
    width: 85px;
    right: 50%;
    top: 65px;
    transform: translateX(50%);
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%), 0 0 2px 0 rgb(0 0 0 / 12%);
    transition: 0.2s;

    &::after {
      content: "";
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #ffffff;
    }

    .user-btn {
      div {
        cursor: pointer;
      }
    }

    .user_info {
      border-bottom: 1px solid #e0e0e0;
      padding: 12px;
      line-height: 18px;

      .user_name {
        font-weight: 600;
        cursor: pointer;
      }

      .user_school {
        font-weight: normal;
      }

      .action {
        position: absolute;
        top: 16px;
        right: 16px;

        i {
          cursor: pointer;
        }
      }
    }

    .logout {
      padding: 12px;
      cursor: pointer;
    }
  }

  .menu-box {
    // width: 1220px;
    min-width: 1275px;
    @media screen and (max-width: 1275px) {
      min-width: 0;
    }
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu {
      display: flex;

      .login-title {
        margin-right: 1.6vw;
        text-align: center;
        align-items: center;
        display: flex;
        height: 72px;
        cursor: pointer;

        img {
          // width: 114px;
          // margin-right: 10px;
        }

        p {
          font-size: 24px;
          color: #000;
          margin-bottom: 0;
        }

        span {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
        }
      }

      .menu-list.on {
        &::after {
          background-color: #2474ed;
        }
      }

      .menu-list {
        white-space: nowrap;
        padding: 0 1vw;
        line-height: 72px;
        color: rgba(0, 0, 0, 0.65);
        // opacity: 0.8;
        cursor: pointer;
        position: relative;
        margin: 0 4px;

        .child-list {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          padding: 8px 0;
          top: 90px;
          left: 50%;
          transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          background-color: #ffffff;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid #ebeef5;
          border-radius: 5px;
          z-index: 999;
          transition: 0.3s linear;
          -moz-transition: 0.3s linear;
          /* Firefox 4 */
          -webkit-transition: 0.3s linear;
          /*Safari 和 Chrome */
          -o-transition: 0.3s linear;
          /* Opera */
          white-space: nowrap;
          ul {
            margin-bottom: 0;
          }

          &::after {
            content: "";
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            /* IE 9 */
            -moz-transform: translateX(-50%);
            /*Firefox*/
            -webkit-transform: translateX(-50%);
            /* Safari 和 Chrome */
            -o-transform: translateX(-50%);
            /* Opera */
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #ffffff;
          }

          ul {
            li {
              padding: 0 20px;
              line-height: 34px;

              &:hover {
                color: #2474ed;
              }
            }

            .childOn {
              background-color: #2474ed;
              color: #ffffff;

              &:hover {
                color: #ffffff;
              }
            }
          }
        }

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          border-radius: 2px;
          content: "";
          transition: 0.3s linear;
          -moz-transition: 0.3s linear;
          /* Firefox 4 */
          -webkit-transition: 0.3s linear;
          /*Safari 和 Chrome */
          -o-transition: 0.3s linear;
          /* Opera */
        }

        .i-style {
          transition: 0.3s linear;
          -moz-transition: 0.3s linear;
          /* Firefox 4 */
          -webkit-transition: 0.3s linear;
          /*Safari 和 Chrome */
          -o-transition: 0.3s linear;
          /* Opera */
        }

        &:hover {
          .child-list {
            top: 76px;
            opacity: 1;
            visibility: initial;
            transition: 0.3s linear;
            -moz-transition: 0.3s linear;
            /* Firefox 4 */
            -webkit-transition: 0.3s linear;
            /*Safari 和 Chrome */
            -o-transition: 0.3s linear;
            /* Opera */
          }

          &:after {
            background-color: #2474ed;
            transition: 0.3s linear;
            -moz-transition: 0.3s linear;
            /* Firefox 4 */
            -webkit-transition: 0.3s linear;
            /*Safari 和 Chrome */
            -o-transition: 0.3s linear;
            /* Opera */
          }

          // border-bottom: 4px solid #2474ED;
          .i-style {
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            /* IE 9 */
            -moz-transform: rotate(180deg);
            /*Firefox*/
            -webkit-transform: rotate(180deg);
            /* Safari 和 Chrome */
            -o-transform: rotate(180deg);
            /* Opera */
            transition: 0.3s linear;
            -moz-transition: 0.3s linear;
            /* Firefox 4 */
            -webkit-transition: 0.3s linear;
            /*Safari 和 Chrome */
            -o-transition: 0.3s linear;
            /* Opera */
          }
        }
      }
    }

    .logo {
      font-family: NotoSerifCJKsc;
      line-height: 72px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 40px;
    }

    .operate {
      display: flex;
      align-items: center;
      cursor: pointer;
      .versions-icon {
        width: 66px;
        height: 24px;
        margin-right: 12px;
      }
      .btn {
        margin-right: 25px;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;

        &:hover {
          color: #2474ed;
        }
      }
    }

    .user-box {
      // width: 32px;
      // height: 32px;
      // color: rgba(0, 0, 0, 0.85);
      // border: solid 0.5px #e0e0e0;
      // background-color: #eeeeee;
      // border-radius: 32px;
      // line-height: 32px;

      // text-align: center;
      // position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      .user-intro {
        p {
          margin-bottom: 0;
        }

        span {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
        }

        // display:;
      }

      .avatar {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;

        img {
          width: 100%;
        }
      }
    }
  }
  .expiresTime {
    background-color: #fff0f0;
    color: #ff3535;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    width: 100%;
    height: 36px;
  }
}
</style>
